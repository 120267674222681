.app {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100%;
  background: #fff;
}

.header {
  padding: 16px 16px 0;
  text-align: center;
}

.logo {
  max-width: min(100%, 600px);
}

.nav {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 24px 0 16px;
}

.navButton {
  all: unset;
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: 3px solid transparent;
  font-size: 1.25rem;
}

.navButton:not(.navButtonActive):hover {
  border-bottom-color: #ccc;
}

.navButtonActive {
  border-bottom-color: #000;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
}

.galleryContainer {
  display: grid;
  grid-template-rows: 1fr;
}
